import { Flex } from "antd";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { Sidebar } from "@/pages/news/ui/news/sidebar";
import { Header } from "@/widgets/header";
import { UseCaseFooter } from "@/entities/use-cases";
import { COLOR_NAMES } from "@/shared/const/color-names.ts";
import { useToTop } from "@/shared/hooks";
import { LandingContent, PageHeader } from "@/shared/ui";

export const News: FC = () => {
	useToTop();

	return (
		<Flex align="center" justify="center" vertical className="contacts">
			<div style={{ background: COLOR_NAMES.BackgroundWhite, width: "100%" }}>
				<LandingContent>
					<Header/>
				</LandingContent>
			</div>
			<PageHeader
				headerText="our"
				italicHeaderText="news"
				description="We're eager to hear from you! Share your inquiries, feedback, or requests
with us by filling out the form below. Our team will respond promptly to assist you."
			/>
			<div style={{
				maxWidth: 1280,
				width: "100%",
				padding: "32px 0"
			}}>
				<Sidebar>
					<Outlet/>
				</Sidebar>
			</div>
			<div className="full-width">
				<UseCaseFooter/>
			</div>
		</Flex>
	);
};
