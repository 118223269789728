import { useHover } from "ahooks";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useRef } from "react";

import linkedin from "@/assets/shared/linkedin_team.svg";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";

import "./CardTeam.scss";

interface ICard {
	fullName: string;
	positionName: string;
	description: string;
	image: string;
	href: string;
	colorImage: string;
	cover?: boolean;
}

export const CardTeam: FC<ICard> = ({ fullName, positionName, description, image, href, cover, colorImage }) => {
	const { isTablet, isMobileS, isMobileL } = useMediaQueries();
	const imageRef = useRef(null);
	const isHover = useHover(imageRef);

	return (
		<Flex vertical className="card-team">
			<Flex className="full-height gap-s" vertical>
				<Flex ref={imageRef} align="center" justify="center" className="card-team__image">
					<img style={{ borderRadius: 16, objectPosition: cover ? "100% 20%" : "" }} src={isHover ? colorImage : image} alt={fullName} className={cn("full-width full-height", {
						"image-contain": !cover,
						"image-cover": cover
					})} />
				</Flex>
				<Flex className="card-team__content gap-s" vertical justify={isMobileL ? "flex-end" : "space-between"}>
					<Flex gap="small" vertical>
						<Typography.Text className={cn(TEXT_CLASSNAMES.HeadlineH2, {
							[TEXT_CLASSNAMES.HeadlineH3]: isTablet,
							[TEXT_CLASSNAMES.HeadlineH4]: isMobileS
						})}>
							{fullName}
						</Typography.Text>
						<Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, {
							[TEXT_CLASSNAMES.SmRegular]: isTablet,
							[TEXT_CLASSNAMES.XsRegular]: isMobileS
						})}>
							{positionName}
						</Typography.Text>
						<a
							className="card-team__link flex-ctr-ctr"
							target="_blank"
							href={href}
						>
							<img src={linkedin} alt="Linkedin" />
						</a>
					</Flex>
					{!isMobileL && (
						<Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, {
							[TEXT_CLASSNAMES.XsRegular]: isTablet
						})}>
							{description}
						</Typography.Text>
					)}
				</Flex>
			</Flex>
			{isMobileL && !isMobileS && (
				<Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, {
					[TEXT_CLASSNAMES.XsRegular]: isTablet
				})}>
					{description}
				</Typography.Text>
			)}
		</Flex>
	);
};
