import { useAtom } from "@reatom/npm-react";
import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { useNavigate, useParams } from "react-router-dom";
import { viewerRegisterAtom } from "@/entities/viewer";
import { applyPromoCodeResource, checkPromoCodeResource, getPaymentCredentialsResource } from "@/shared/api/payment";
import { PAYMENT_ERRORS_PLAN_REDIRECT } from "@/shared/const/payment.ts";

export const useBehavior = () => {
	const navigate = useNavigate();
	const { termType } = useParams();

	const [promoCode, setPromoCode] = useState("");
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	const [viewer] = useAtom(viewerRegisterAtom);

	useEffect(() => {
		const init = async () => {
			try {
				setLoading(true);
				const { data: credentials } = await getPaymentCredentialsResource(viewer?.key ?? "");

				if (credentials === null || PAYMENT_ERRORS_PLAN_REDIRECT.includes(credentials?.message ?? "")) {
					navigate("/plan");
				}
			} catch (e) {
				console.error(e);
			} finally {
				setLoading(false);
			}
		};

		init();
	}, []);

	const onApply = async () => {
		try {
			setLoading(true);
			const { data: checked } = await checkPromoCodeResource(viewer?.key ?? "", promoCode, termType ?? "");

			if (checked.code) {
				await applyPromoCodeResource(viewer?.key ?? "", checked.code);

				if (checked.percent_off === 100) {
					TagManager.dataLayer({
						dataLayer: {
							event: "start_subscription_100_percent_off_promo_code"
						}
					});

					navigate("/payment-success");
				} else {
					navigate("/payment");
				}
			} else {
				setError("The promo code you entered is invalid. Please check the code and try again.");
			}
		} catch (e) {
			setError("The promo code you entered is invalid. Please check the code and try again.");
		} finally {
			setLoading(false);
		}
	};

	const onSkip = () => {
		navigate("/payment");
	};

	return {
		onSkip,
		onApply,
		loading,
		error,
		setPromoCode
	};
};
