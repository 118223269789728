import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { useNavigate } from "react-router-dom";

import {
	createSetupIntentResource,
	subscribeCustomerResource,
	subscribeTrialCustomerResource
} from "@/shared/api/payment";

import { PlanParams, sleep } from "../lib";
import { useStore } from "./useStore.ts";

export const useBehavior = () => {
	const navigate = useNavigate();

	const [planParams, setPlanParams] = useState<PlanParams>({
		termType: "1",
		interval: "month"
	});
	const [loading, setLoading] = useState(false);

	const {
		getPossibleSubscriptions,
		possibleSubscriptions,
		logout,
		promoCode,
		viewer,
		getSubscription,
		sessionLogin,
		subscription,
		callError
	} = useStore();

	const startUsing = async () => {
		try {
			setLoading(true);

			const termType = Number(planParams.termType);
			const interval = planParams.interval;

			const { data } = await subscribeCustomerResource({
				interval,
				token: viewer?.key ?? "",
				promoCode: promoCode?.code ?? "",
				productId: termType
			});

			if (promoCode) {
				if (promoCode.percent_off === 100) {
					if (data?.message === "Already subscribed") {
						navigate(`/promo-code/${termType}`);
					} else {
						TagManager.dataLayer({
							dataLayer: {
								event: "start_subscription_100_percent_off_promo_code"
							}
						});

						navigate("/payment-success");
					}
				} else {
					navigate("/payment");
				}
			} else {
				navigate(`/promo-code/${termType}`);
			}
		} catch (e) {
			callError(e);
			throw new Error(e as string);
		} finally {
			setLoading(false);
		}
	};

	const onChange = (params: PlanParams) => {
		setPlanParams(params);
	};

	const startTrial = async () => {
		try {
			setLoading(true);
			await subscribeTrialCustomerResource(viewer?.key ?? "").then(() => {
				sleep(1000);
			});

			await getSubscription(viewer?.key ?? "");

			sessionLogin(viewer!);
			TagManager.dataLayer({
				dataLayer: {
					event: "start_trial_subscription"
				}
			});

			navigate("/main/projects");
		} catch (e) {
			callError(e);
			throw new Error(e as string);
		} finally {
			setLoading(false);
		}
	};

	const startSubscriptionWithTrial = async () => {
		try {
			setLoading(true);

			const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY, { locale: "en" });
			localStorage.setItem("interval", planParams.interval);
			localStorage.setItem("blooper_subscription_id", planParams.termType);
			localStorage.setItem("promocode", promoCode?.code ?? "");

			const params = {
				success_url: `${window.location.origin}/trial-move-success`,
				fail_url: `${window.location.origin}/trial-move-fail`
			};

			const { data } = await createSetupIntentResource(viewer?.key ?? "", params);
			localStorage.setItem("session_id", data.session_id);
			const stripe = await stripePromise;

			await stripe?.redirectToCheckout({
				sessionId: data.session_id
			});
		} catch (e) {
			callError(e);
			throw new Error(e as string);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const init = async () => {
			try {
				await getSubscription(viewer?.key ?? "");
			} catch (e) {
				throw new Error(e as string);
			} finally {
				getPossibleSubscriptions();
			}
		};

		init();
	}, []);

	const isShowTrial = subscription?.trial_status === "on_trial" || subscription?.trial_status === "no_trial" || subscription === null;

	return {
		planParams,
		loading,
		startUsing: subscription?.trial_status === "on_trial" ? startSubscriptionWithTrial : startUsing,
		onChange,
		startTrial,
		logout,
		possibleSubscriptions,
		isShowTrial
	};
};
