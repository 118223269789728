import { useGoogleLogin } from "@react-oauth/google";
import { useAction } from "@reatom/npm-react";
import { Button, Flex, Form, Input, Progress, Typography } from "antd";
import cn from "classnames";
import { FC, useState } from "react";
import TagManager from "react-gtm-module";
import { useNavigate } from "react-router-dom";
import { colors, percent } from "@/pages/register/utils";
import { PLAN_EMAIL, PlanEmail, sessionLoginAction, sessionPreLoginAction, subscriptionAction } from "@/entities/viewer";
import googleIcon from "@/assets/shared/google_icon.svg";
import { axiosErrorHandler } from "@/shared/api";
import { createUserGoogleResource, createUserResource, loginUserGoogleResource } from "@/shared/api/auth";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { REG_EXP } from "@/shared/const/reg-exp.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useLocalStorage } from "@/shared/hooks";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";
import { DisableSubmitButton } from "@/shared/ui";
import { validateEmail, validatePasswordReg } from "@/shared/validation";
import { IMessageError } from "@/vite-env";

type FieldType = {
	username: string;
	email: string;
	password: string;
};

type Props = {
	isFromInstagram: boolean;
	onDocumentRedirect: (doc: string) => void;
}

export const RegisterForm: FC<Props> = ({ isFromInstagram, onDocumentRedirect }) => {
	const [form] = Form.useForm();
	const { isMobileL } = useMediaQueries();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [_, setUserData] = useLocalStorage<PlanEmail | null>(PLAN_EMAIL, null);
	const navigate = useNavigate();
	const sessionPreLogin = useAction(sessionPreLoginAction);
	const sessionLogin = useAction(sessionLoginAction);
	const getSubscription = useAction(subscriptionAction);

	const email = Form.useWatch("email", { form, preserve: true });
	const username = Form.useWatch("username", { form, preserve: true });
	const password = Form.useWatch("password", { form, preserve: true });

	const validatedPassword = validatePasswordReg(password);
	const passwordChecks = Object.values(validatedPassword).filter((value) => value);

	const onRegister = async (data: FieldType) => {
		try {
			await createUserResource({
				password: data.password,
				email: data.email,
				name: data.username
			});

			TagManager.dataLayer({
				dataLayer: {
					event: "sign_up",
					signupMethod: "email"
				}
			});

			setUserData({ email });
			navigate(`/success-email/${email}`);
		} catch (err) {
			const { type, error } = axiosErrorHandler<IMessageError<string>>(err as Error);

			if (type === "axios-error") {
				if (error.response?.status === 403) {
					setError("Incorrect email or password");
				} else if (error.response?.status === 409) {
					setError("Incorrect email or password");
				} else {
					setError("Oops, something went wrong");
				}
			} else {
				setError("Oops, something went wrong");
			}
		}
	};

	const onGoogleRegister = useGoogleLogin({
		onSuccess: async (tokenResponse) => {
			try {
				setLoading(true);
				createUserGoogleResource(tokenResponse)
					.then(({ data: credential }) => {
						TagManager.dataLayer({
							dataLayer: {
								event: "sign_up",
								signupMethod: "google"
							}
						});
						sessionPreLogin(credential);
						navigate("/plan");
					})
					.catch((err) => {
						const { type, error } = axiosErrorHandler<IMessageError<string>>(err as Error);

						if (type === "axios-error") {
							if (error?.status === 409) {
								loginUserGoogleResource(tokenResponse)
									.then(async ({ data: credential }) => {
										TagManager.dataLayer({
											dataLayer: {
												event: "login",
												loginMethod: "google"
											}
										});

										await getSubscription(credential.key);

										sessionLogin(credential);
										navigate("/main/projects");
									})
									.catch((err) => {
										const status = err?.response?.status;

										if (status === 409) {
											setError("Incorrect email or password");
										} else {
											setError("Oops, something went wrong");
										}
									});
							} else {
								setError("Oops, something went wrong");
							}
						}
					});
			} catch (err) {
				const { type, error } = axiosErrorHandler<IMessageError<string>>(err as Error);

				if (type === "axios-error") {
					if (error?.status === 409) {
						setError("Incorrect email or password");
					} else {
						setError("Oops, something went wrong");
					}
				}
			} finally {
				setLoading(false);
			}
		},
		onError: (errorResponse) => console.log("==========>errorResponse", errorResponse)
	});

	const strokeColor = colors(passwordChecks);

	return (
		<Form
			form={form}
			name="basic"
			initialValues={{ remember: true }}
			onFinish={onRegister}
			autoComplete="off"
			className="register-form"
			validateTrigger={["onSubmit"]}
		>
			<Flex vertical className="gap-xs">
				<Form.Item<FieldType>
					name="username"
					rules={[
						{ required: true, message: "Please input your name!" }
					]}
					hasFeedback
					validateStatus={username ? "success" : ""}
				>
					<Input
						onChange={(event) => form.setFieldValue("username", event.target.value)}
						placeholder="Name"
					/>
				</Form.Item>
				<Form.Item<FieldType>
					name="email"
					rules={[
						{ required: true, message: "Please input your email!" },
						{ type: "email", message: "The input is not valid E-mail!" }
					]}
					hasFeedback
					validateStatus={validateEmail(email)}
				>
					<Input
						onChange={(event) => form.setFieldValue("email", event.target.value)}
						placeholder="Email"
					/>
				</Form.Item>
				<Form.Item<FieldType>
					name="password"
					style={{
						maxWidth: 380
					}}
					rules={[
						{ required: true, message: "Please input your password!" },
						{ min: 8, message: "Password must be at least 8 characters" },
						{ pattern: REG_EXP.upperCaseLetter, message: "Min. eight characters at least one uppercase letter" }
					]}
				>
					<Input.Password
						onChange={(event) => form.setFieldValue("password", event.target.value)}
						placeholder="Password"
					/>
					<Progress strokeColor={strokeColor} percent={percent(passwordChecks)} showInfo={false} />
					<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>
						The password must be at least 8 characters long and include at least one uppercase letter.
					</Typography.Text>
				</Form.Item>
			</Flex>
			<Flex vertical className="register-form__buttons gap-xs">
				<DisableSubmitButton loading={loading} errorMessage={error} type="primary" form={form}>
					<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular)}>
						Sign up
					</Typography.Text>
				</DisableSubmitButton>
				{!isFromInstagram && (
					<Button
						htmlType="button"
						className="register-form__buttons__google"
						icon={<img src={googleIcon} alt="google icon" />}
						onClick={() => onGoogleRegister()}
						loading={loading}
					>
						<Typography.Text className={cn(COLOR_CLASSNAMES.TextSecondary, TEXT_CLASSNAMES.XsRegular)}>
							Sign up with Google
						</Typography.Text>
					</Button>
				)}
			</Flex>
			<Typography.Text className={cn(COLOR_CLASSNAMES.TextSecondary, TEXT_CLASSNAMES.SmRegular, {
				[TEXT_CLASSNAMES.XsRegular]: isMobileL
			})}>
				By signing up I agree with
				<Typography.Text
					onClick={() => onDocumentRedirect("/terms-of-conditions")}
					className={cn(COLOR_CLASSNAMES.TextSecondary, TEXT_CLASSNAMES.SmRegular, "register-form__underline pointer", {
						[TEXT_CLASSNAMES.XsRegular]: isMobileL
					})}>
					Terms of Service
				</Typography.Text>
				{!isMobileL && <br/>}
				and
				<Typography.Text
					onClick={() => onDocumentRedirect("/privacy-policy")}
					className={cn(COLOR_CLASSNAMES.TextSecondary, TEXT_CLASSNAMES.SmRegular, "register-form__underline pointer", {
						[TEXT_CLASSNAMES.XsRegular]: isMobileL
					})}>
					Privacy Policy
				</Typography.Text>
				and
				<Typography.Text
					onClick={() => onDocumentRedirect("/acceptable-use-policy")}
					className={cn(COLOR_CLASSNAMES.TextSecondary, TEXT_CLASSNAMES.SmRegular, "register-form__underline pointer", {
						[TEXT_CLASSNAMES.XsRegular]: isMobileL
					})}>
					Acceptable Use Policy
				</Typography.Text>
			</Typography.Text>
		</Form>
	);
};
