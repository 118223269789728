import { Flex, Typography } from "antd";
import { FC } from "react";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { LandingContent } from "@/shared/ui";

import "./PageHeader.scss";

type Props = {
	headerText: string;
	italicHeaderText: string;
	description?: string;
}

export const PageHeader: FC<Props> = ({ italicHeaderText, headerText, description }) => {
	return (
		<Flex justify="center" className="page-header full-width">
			<LandingContent>
				<Flex vertical justify="center" align="center" className="page-header__content gap-s text-center">
					<Typography.Text className="text-center page-header--title">
						{headerText} <span className="page-header--italic">{italicHeaderText}</span>
					</Typography.Text>
					{!!description && (
						<Typography.Text className={TEXT_CLASSNAMES.MdRegular}>{description}</Typography.Text>
					)}
				</Flex>
			</LandingContent>
		</Flex>
	);
};
