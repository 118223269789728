import { Flex } from "antd";
import { FC } from "react";

import ReactGA from "react-ga4";
import { Header } from "@/widgets/header";
import { UseCaseBlock, UseCaseContent, UseCaseFooter, UseCaseTitle, UseCaseWrapper } from "@/entities/use-cases";

import { useToTop } from "@/shared/hooks";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";
import { LandingContent, TrialButton } from "@/shared/ui";
import blooperExamplePDF from "@/assets/blooper-example.pdf";
import pitch from "@/assets/use-cases/video/pitch.png";
import preview from "@/assets/use-cases/video/preview.png";
import storyboard from "@/assets/use-cases/video/storyboard.png";
import themes from "@/assets/use-cases/video/themes.png";

import "./UseCaseVideo.scss";

export const UseCaseVideo: FC = () => {
	useToTop();
	const { isMobileXL } = useMediaQueries();
	const onButtonClick = () => {
		const link = document.createElement("a");
		link.href = blooperExamplePDF;
		link.download = "Blooper example.pdf";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		ReactGA.event({
			category: "pdf download",
			action: "download pdf from use case storyboard"
		});
	};

	return (
		<Flex vertical>
			<LandingContent>
				<Header />
			</LandingContent>
			<UseCaseTitle
				isComingSoon
				description="Polish your ideas and share a ready-to-present deck in minutes"
				italicHeaderText="Ad Campaigns"
				headerText="Create Custom Pitch Decks for"
				isBrItalic
				style={{ maxWidth: 690 }}
			/>
			<UseCaseBlock
				previewImg={preview}
				title="Generate your pitch deck"
				description="Create polished pitch decks faster by selecting pre-production materials, adjusting layouts, and customizing colors. Save time, streamline approvals, and focus on delivering more projects with clarity and confidence."
			/>
			<UseCaseWrapper>
				<Flex className="gap-m">
					<UseCaseContent
						flex={1}
						title="Storyboard views"
						description="Explore your storyboard from multiple perspectives—shot lists, tiled layouts, and more. Choose the format that fits your planning style and keeps your team aligned effortlessly."
					>
						<Flex vertical className="gap-m">
							<TrialButton onClick={onButtonClick} text="View example PDF" />
							<img src={storyboard} alt="storyboard" className="image-contain video-block-img__1" />
						</Flex>
					</UseCaseContent>
				</Flex>
				<Flex vertical={isMobileXL} className="gap-m">
					<UseCaseContent flex={1} title="Different themes and preview" description="Easily adapt the theme of your pitch deck to align with your project’s vision and style, ensuring that it that it is tailored to your needs.">
						<img src={themes} alt="themes" className="image-contain video-block-img__2" />
					</UseCaseContent>
					<UseCaseContent id="pitch" flex={1} title="Pitch deck customization" description="Select the specific preproduction documents to include in your pitch deck and customize the color scheme to create a presentation that truly reflects your project's identity.">
						<img src={pitch} alt="pitch" className="image-contain video-block-img__3" />
					</UseCaseContent>
				</Flex>
			</UseCaseWrapper>
			<UseCaseFooter />
		</Flex>
	);
};
