import { useAtom } from "@reatom/npm-react";
import { Flex } from "antd";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { useNavigate } from "react-router-dom";
import { viewerRegisterAtom } from "@/entities/viewer";
import { moveFromTrialResource, updateTrialPaymentDetails } from "@/shared/api/payment";
import { Spinner } from "@/shared/ui";

export const TrialMoveSuccess = () => {
	const [viewer] = useAtom(viewerRegisterAtom);
	const navigate = useNavigate();

	useEffect(() => {
		const init = async () => {
			try {
				const interval = localStorage.getItem("interval");
				const blooperSubscriptionId = localStorage.getItem("blooper_subscription_id");
				const promocode = localStorage.getItem("promocode");

				const session = localStorage.getItem("session_id");
				await updateTrialPaymentDetails(viewer?.key ?? "", session ?? "");
				await moveFromTrialResource(viewer?.key ?? "", {
					interval: interval ?? "month",
					blooper_subscription_id: blooperSubscriptionId ? Number(blooperSubscriptionId) : 1,
					promocode: promocode ?? ""
				});

				TagManager.dataLayer({
					dataLayer: {
						event: "start_subscription_from_trial"
					}
				});

				navigate("/payment-success");
			} catch (e) {
				console.error(e);
			}
		};

		init();
	}, []);

	return (
		<Flex>
			<Spinner isOverlay={true} />
		</Flex>
	);
};
