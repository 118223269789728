import { useAtom } from "@reatom/npm-react";
import { useClickAway } from "ahooks";
import { Avatar, Button, Flex, Popover, Typography } from "antd";
import cn from "classnames";
import { FC, useRef, useState } from "react";

import {
	EditButton,
	generateImageAtom,
	regenerateImageAtom,
	TStoryboardShot,
	useShotStoryboard
} from "@/entities/storyboard";
import { ImageHoverAction } from "@/entities/storyboard/ui/image-hover-action";
import { COLOR_CLASSNAMES, COLOR_NAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import { toCharacter } from "@/shared/methods/toCharacter";
import { Spinner } from "@/shared/ui";
import { PopoverContent } from "./PopoverContent";

import bottom from "@/assets/storyboard/bottom.svg";
import closeEye from "@/assets/storyboard/close-eye.svg";
import emptyShot from "@/assets/storyboard/empty-shot.svg";
import eye from "@/assets/storyboard/eye.svg";
import refresh from "@/assets/storyboard/refresh.svg";
import timeImg from "@/assets/storyboard/time.svg";

import "./SceneShotGrid.scss";

type GeneralProps = TStoryboardShot & {
	sceneId: string;
}

type RenderProps = GeneralProps & {
	renderType: "render";
}

type ExportProps = GeneralProps & {
	renderType: "export";
	style: React.CSSProperties;
	imageStyle: React.CSSProperties;
	imageContainerStyle: React.CSSProperties;
}

type Props = RenderProps | ExportProps;

export const SceneShotGrid: FC<Props> = ({
	id,
	time,
	location,
	// eslint-disable-next-line camelcase
	shot_description,
	props,
	dialogue,
	characters,
	image,
	shotCharacteristics,
	cameraMovement,
	shotSettings,
	sceneId,
	cameraAngle,
	...rest
}) => {
	const [isShowDetails, setIsShowDetails] = useState(false);
	const [generateImage] = useAtom(generateImageAtom);
	const [regenerateImage] = useAtom(regenerateImageAtom);

	const {
		onGenerate,
		onRedirect,
		imageRef,
		isHoverImage,
		onCharacterRedirect,
		descriptionSize,
		descriptionRef,
		containerRef,
		onScroll
	} = useShotStoryboard();
	const ref = useRef(null);
	const pending = regenerateImage[id]?.pending ?? generateImage[id]?.pending;
	const error = regenerateImage[id]?.error ?? generateImage[id]?.error;
	const imageGenerated = regenerateImage[id]?.image ?? generateImage[id]?.image;
	const currentImage = (imageGenerated ?? image);

	useClickAway(() => setIsShowDetails(false), ref, ["click", "contextmenu"]);

	const isExport = rest.renderType === "export";
	const propsArray = (props ?? "").split(",")?.filter((el) => el);

	return (
		<Flex className="scene-shot-grid" style={isExport ? rest.style : undefined} vertical ref={ref}>
			{isShowDetails && <div className="scene-shot-grid__overlay" />}
			<Flex ref={imageRef} align="center" justify="center" style={isExport ? rest.imageContainerStyle : undefined} className="scene-shot-grid__img">
				{pending && <Flex align="center" justify="center" className="scene-shot-grid__overlay"><Spinner /></Flex>}
				{error
					? (
						<Flex vertical className="gap-xxs error-card">
							<Flex vertical align="center" className="gap-4">
								<Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>Something went wrong</Typography.Text>
								<Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>Please try again</Typography.Text>
							</Flex>
							<Button
								icon={<img src={refresh} alt="refresh" />}
								className="scene-shot-grid__error-regenerate flex-bt-ctr"
								onClick={() => onGenerate(id)}
							>
								<Typography className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextWhitePrimary)}>
									Generate
								</Typography>
							</Button>
						</Flex>
					)
					: (
						<>
							<img className={cn({
								"scene-shot-grid__img__full": currentImage,
								"scene-shot-grid__img__default": !currentImage
							})} src={currentImage || emptyShot} alt="shot image" style={isExport ? rest.imageStyle : undefined} />
							<ImageHoverAction
								image={currentImage}
								onGenerate={() => onGenerate(id)}
								isHoverImage={isHoverImage}
							/>
						</>
					)}
			</Flex>
			<Flex vertical className="scene-shot-grid__line gap-xxs">
				<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Location</Typography.Text>
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{location}</Typography.Text>
				<Flex className="gap-xxs" wrap="wrap">
					<Flex align="center" className="gap-4 scene-shot-grid__tag">
						<img style={{ width: 16, height: 16 }} src={timeImg} className="image-contain" alt="time" />
						<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.MetalGray700)}>
							{`${time} sec`}
						</Typography.Text>
					</Flex>
					{shotCharacteristics.map((tag, index) => (
						<Flex className="scene-shot-grid__tag" key={index.toString(36)}>{tag}</Flex>
					))}
				</Flex>
			</Flex>
			<Flex style={{ position: "relative" }}>
				<Flex
					ref={containerRef}
					vertical
					className="scene-shot-grid__line scene-shot-grid__line__height scene-shot-grid__line__description gap-xxs"
				>
					<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Description</Typography.Text>
					{/* eslint-disable-next-line camelcase */}
					{!shot_description && <Typography.Text>-</Typography.Text>}
					<Typography.Text ref={descriptionRef} className={TEXT_CLASSNAMES.XsRegular}>
						{/* eslint-disable-next-line camelcase */}
						{shot_description}
					</Typography.Text>
				</Flex>
				{((descriptionSize?.height || 0) > 60) && (
					<Flex
						align="center"
						justify="center"
						className="scene-shot-grid__line__description--scroll"
						role="button"
						onClick={onScroll}
					>
						<img style={{ width: 20, height: 20 }} src={bottom} alt="bottom" />
					</Flex>
				)}
			</Flex>
			<Flex vertical className="scene-shot-grid__line scene-shot-grid__line__height gap-xxs">
				<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Dialogue</Typography.Text>
				{!dialogue.length && <Typography.Text>-</Typography.Text>}
				{!!dialogue.length && <Typography.Text className={cn("scene-shot-grid__line--dialogue", TEXT_CLASSNAMES.XsRegular)}>{dialogue}</Typography.Text>}
			</Flex>
			<Flex vertical className="scene-shot-grid__line scene-shot-grid__line__height gap-xxs">
				<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Characters</Typography.Text>
				{!characters.length && <Typography.Text>-</Typography.Text>}
				<Flex className="gap-xxs">
					{[...new Map(characters.map(item => [item.key, item])).values()].map((character, index) => (
						<Avatar
							style={{ borderColor: COLOR_NAMES.MetalGray300, backgroundColor: COLOR_NAMES.MetalGray50 }}
							key={index.toString(36)}
							size={46}
							icon={<img src={toCharacter(character.key, "4")} alt="character" />}
							onClick={() => onCharacterRedirect(character.key)}
						/>
					))}
				</Flex>
			</Flex>
			{(!!propsArray?.length && isExport) && (
				<Flex vertical className="scene-shot-grid__line scene-shot-grid__line__height gap-xxs">
					<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Props</Typography.Text>
					<Flex className="gap-xxs" wrap="wrap">
						{propsArray.map((tag, index) => (
							<Flex vertical className="scene-shot-grid__tag" style={{ height: 30 }} key={index.toString(36)}>
								<Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>
									{tag}
								</Typography.Text>
							</Flex>
						))}
					</Flex>
				</Flex>
			)}
			{((!!shotSettings.length || !!cameraAngle) && isExport) && (
				<Flex vertical className="scene-shot-grid__line scene-shot-grid__line__height gap-xxs">
					<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Shot settings</Typography.Text>
					<Flex wrap="wrap" className="gap-xxs">
						{shotSettings.map((tag, index) => (
							<Flex style={{ height: 30 }} className="scene-shot-grid__tag" key={index.toString(36)}>
								<Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>
									{tag}
								</Typography.Text>
							</Flex>
						))}
						<Flex className="scene-shot-grid__tag" style={{ height: 30 }} key={cameraAngle}>
							<Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>
								{cameraAngle}
							</Typography.Text>
						</Flex>
					</Flex>
				</Flex>
			)}
			{(!!cameraMovement && isExport) && (
				<Flex vertical className="scene-shot-grid__line scene-shot-grid__line__height gap-xxs">
					<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Camera movement</Typography.Text>
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
						{cameraMovement}
					</Typography.Text>
				</Flex>
			)}
			<div className="scene-shot-grid__line scene-shot-grid__line__actions">
				<Flex className="gap-xxs">
					<Popover
						placement="topLeft"
						content={<PopoverContent cameraAngle={cameraAngle} props={props} cameraMovement={cameraMovement} shotSettings={shotSettings} />}
						trigger="click"
						open={isShowDetails}
						onOpenChange={(open) => setIsShowDetails(open)}
					>
						<Flex onClick={() => setIsShowDetails(!isShowDetails)} role="button" justify="space-between" align="center" className="gap-4 scene-shot-grid__details pointer">
							<img src={isShowDetails ? closeEye : eye} alt={isShowDetails ? "Hide details" : "Show details"} />
							<span className={TEXT_CLASSNAMES.XsRegular}>
								{isShowDetails ? "Hide details" : "Show details"}
							</span>
						</Flex>
					</Popover>
					<EditButton isActive onClick={() => onRedirect(id, sceneId)}>
						<Typography className={TEXT_CLASSNAMES.XsRegular}>
							Edit shot
						</Typography>
					</EditButton>
				</Flex>
			</div>
		</Flex>
	);
};
